.testimonials{
    height: 500px;
    margin: 0px auto;
    width: 75vw;
    overflow: scroll;
}

.customer_endorsement{
    font-size: 48px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-family: cursive;
    margin: 4px;
}

.comment{
    margin: 0px auto;
    text-align: left;
    padding: 16px;
    font-size: 24px;
}

.author_name{
    margin: 4px auto;
    left: 0px;
    font-size: 18px;
}

.post_date{
    margin: 4px auto;
    font-size: 18px;
}

.comment_content{
    margin: 16px auto;
    font-weight: 700;
}


.customer_create{
    height: 500px;
    margin: 0px auto;
    width: 75vw;
    display: block;
    padding-bottom: 200px;
}


.submit_btn {
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  font-family: arial,sans-serif;
  font-size: 16px;
  line-height: 64px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
}

.submit_btn:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  color: #202124;
}

.submit_btn:focus {
  border-color: #4285f4;
  outline: none;
}




textarea{
    border-radius: 4px;
    margin: 16px;
    font-size: 16px;

}
input{
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    border: none;
    margin: 4px auto;
    padding: 4px;
}

textarea{
    border: none;
    font-size: 18px;
    padding: 4px;
}
.data3_content{
    font-size: 24px;
    padding: 8px;
}

.default_error{
    color: red;
    height: 32px;
    font-size: 18px;
    margin: 8px auto;
    font-weight: 500;

}
.error{
    height: 32px;
    margin: 8px auto;
}