.image1{
    height: 500px;
    display: inline-block;
    border-radius: 5px;
    margin: 8px auto;
}
.about_container{
    width: 55vw;
    display: inline-block;
    vertical-align: top;
    margin: 16px auto;
    text-align: left;
}