.tel_num{
    font-size: 48px;
}

.Terms{
    width: 75vw;
    margin: 16px auto;
}

.uin{
    margin: 4px auto;
    padding: 4px;
}

.LoginContainer{
    
}

.CreateContainer{
    margin: 16px auto;
    padding: 4px;
}

.description{
    width: 300px;
}

input{
    font-size: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    color: rgb(0, 0, 0);
    padding: 4px;
}
select{
    font-size: 18px;
    background-color: black;
    border-radius: 5px;
    color: white;
}
textarea{
    font-size: 18px;
}