.body{

}
.App {
  margin: 0px auto;
  width: 100vw;
  text-align: center;
}
.main_container{
  text-align: center;
}
*{
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}

.gallery{
  border-radius: 5px;
  min-height: 500px;
  position: relative;
  width: 100vw;
}

.gallery_buttons{
  bottom: 0px;
  height: 100%;
  margin: 0px auto;
  position: absolute;
  width: 100%;
}

.f_ward{
  display: inline-block;
}

.c_img{
  color: white;
  display: inline-block;
  padding: 8px;
  
}

.b_ward{
  display: inline-block;

}
.gallery_f_screen{
  height: 100vh;
  width: 100vw;

}

.img_container{
  display: block;
  width: 100vw;
  margin: 8px auto;
}

.img{
  border-radius: 5px;
  max-height: 75vh;
  max-width: 90vw;
  vertical-align: top;
  margin: 0px auto;
  object-fit: contain;
  transition-duration: 1000ms;
}

.btn:hover{
  border: solid 1px white;

}

.btn {
  align-items: center;
  background-color: initial;
  border: solid 2px rgba(255, 255, 255, 0);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 128px;
  justify-content: center;
  line-height: 32px;
  margin: 8px;
  outline: none;
  overflow: hidden;
  padding: 16px;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
}

.btn:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.btn:active {
  outline: 0;
}

.btn:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .btn {
    height: 48px;
  }
}
.img1{
  width: 50vw;
}


.btn1{
  border: none;
  color: white;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 16px auto;
  width: 100vw;
  
  
}
.btn1:hover{

}

.button_container{
  color: black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 0px auto;
  width: 100vw;
}

.button-47 {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.208);
  border: 0 solid #E2E8F0;
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 46px;
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 16px;
  text-decoration: none;
  min-width: 300px;
  width: auto;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 8px;
  background: rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
}

.button-47:hover{
  text-shadow: 2px 2px 4px black;
}

.info{
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  color: black;
  font-size: 24px;
  -webkit-backdrop-filter: blur(10.1px);
  text-align: center;
  margin: 16px;
  padding: 64px;
}

.footer{
  text-align: center;
  padding: 16px
  
}

.customer_rel{
  align-items: center;
  display: flex;
  border-bottom: solid 1px black;
  width: 75vw;
  margin: 16px auto;
}
.customer_rel_b{
  color: black;
  margin: 32px auto;
  padding: 16px;
  display: inline-block;
}
.customer_rel_b:hover{
  border-bottom: solid 2px black;
}

.slogan{
  background: rgba(0, 0, 0, 0.21);
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border-radius: 5px;
  color: white;
  font-weight: 700;
  font-family: cursive;
  font-size: 24px;
  line-height: 32px;
  margin: 16px auto;
  padding: 16px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.564);
}


.contact_container{

}
.span-1{
  font-size: 24px;
}

.logo1{
  width: 20vw;
}


.privacy{
  margin: 16px;
  padding: 16px;
  text-align: left;
}
.terms{
  margin: 16px;
  padding: 16px;
  text-align: left;
}

.load{
  display: flex;
  justify-content: center;
  margin-top: 64px;
}

.loading1{
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: 30;
  width: 250px;
  height:250px ;
  position: absolute;
  opacity: 0%;
}

.loading2{
  animation-name: loading;
  animation-duration: 4s;
  animation-duration: 4s;
  animation-iteration-count: 30;
  width: 250px;
  height:250px ;
  position: absolute;
}

@keyframes loading {
  0% {opacity: 0%;}
  50% {opacity: 100%;}
  100% {opacity: 0%;}
}

.i{
  background-image: url("./components/images/instagram.png");
  background-size: contain;
}

.m{
  background-image: url("./components/images/facebook.png");
  background-size: contain;
}

.about_container{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;}

.about_sum{
  background-color: rgb(229, 229, 229);
  border-radius: 2px;
  color: black;
  font-size: 24px;
  min-height: 250px;
  margin: 16px auto;
  width: 75%;
  margin: 0px auto;
  padding: 64px;
}

.image1_about{
  border-radius: 2px;
  display: inline-block;
  height: 40vh;
  margin: 0px auto;
  vertical-align: top;
}
.about_header{
  margin: 32px auto;
  font-family: cursive;
}

.image2{
  height: 30vh;

}
.image3{
  height: 30vh;

}
.image4{
  height: 30vh;
  
}

.services_Container{
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 24px;
  text-align: center;
  margin: 16px;
  padding: 64px;
}

.title{
  display: inline-block;
  height: 30vh;
}
.title2{
  display: block;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
h2{
  display: block;

}

img{
  border-radius: 2px;
  margin: 16px;
}

.g_image1{
  box-shadow: 1px 1px 4px black;
  display: block;
  height: 500px;
  width: 99vw;
  margin: 8px auto;
  object-fit:cover;
}

.bigger_logo{
  font-size: 92px;
}


.before_after{
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: white;
  font-size: 24px;
  vertical-align: top;
}






.click{
  font-size: 12px;
}

.ackowledgement{
  text-align: center;
  background-color: black;
  color: white;
  padding: 8px;
}


.nav_tel_num{
  font-size: 128px;
}






