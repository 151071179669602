
.dropbtn {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(0, 0, 0);
    padding: 16px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 40px;
    box-shadow: 2px 2px 5px black;
    font-weight: 800;
    text-shadow: 2px 2px 8px rgb(255, 255, 255);
    transition-duration: 250ms;
  }
 .dropdown {
    position: relative;
    display: inline-block;
    margin: 8px;
  }
  
 .dropdown-content {
    display: none;
    position:absolute;
    min-width: 200px;
    z-index: 1;
    right: 20px;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #ffffff;
  }
   
  .nav_note{
    font-size: 24px;
    color: rgb(0, 0, 0);
    margin: 4px;
    padding: 0px;
    text-align: center;  text-shadow: 2px 2px 8px rgb(255, 255, 255);
  
  }

  .nav_note2{
    font-size: 24px;
    color: rgb(255, 255, 255);
    margin: 4px;
    padding: 0px;
    text-align: center;
    text-shadow: 2px 2px 5px black;
  }

  .nav_note_name{
    font-size: 20px;
    color: black;
    margin: 0px;
    padding: 0px;
    min-width: 200px;
    text-align: left;
    text-shadow: 2px 2px 8px rgb(255, 255, 255);
  }

  .web_name{
    width: 90vw;
    margin: 0px auto;
    display: block;
  }

  .est{
    color: white;
    display: inline-block;
    font-size: 16px;
    display: block;
    text-align: right;
  }

  .nav_container{
    background-image: url("../images/IMG_1234.jpg");
    background-size: contain;
    box-shadow: 1px 1px 6px black;
    width: 100vw;
    margin: 0px auto;
    text-align: right;
    text-shadow: 2px 2px 6px rgb(74, 74, 74);
  }
  

.logo{
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.4px);
  -webkit-backdrop-filter: blur(1.4px);
  font-weight: 700;
  font-size: 32px;
  left: 24px;
  padding: 4px;
  margin: 16px auto;
  width: 80vw;
  min-width: 90%;
  text-align: center;
  display: inline-block;
  font-size: 48px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 2px 2px 6px grey;
}

a{
  color: white
}

.slogan_2{
  color: black;
  font-size: 24px;
  line-height: 32px;
  margin: 8px auto;
  text-shadow: 2px 2px 8px rgb(255, 255, 255);
}

